<template>
	<div>
		<s-crud
			title="Rporte de trabajador"
			:filter="filter"
			:config="config"
			add
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col cols="6" lg="6" md="6" sm="6" class="s-col-form">
							<s-select-worker
								v-model="props.item.RpwWorkerID"
								label="Trabajador"
							></s-select-worker>
						</v-col>
						<v-col cols="6" lg="6" md="6" sm="6" class="s-col-form">
							<s-date
								v-model="props.item.RpwDate"
								label="Fecha"
							>
							</s-date>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-card class="mt-5 pb-5">
								<s-toolbar 
									label="Actividades"
									color="#E0F7F2"
									add
									@add="addActivity()"
								></s-toolbar>
								<v-container>
									<v-row>
										<v-col cols="4" lg="4" md="12" sm="12" class="s-col-form">
											<s-time
												v-model="activity.RpdBeginHour"
												label="Hora Inicio"
											>
											</s-time>
										</v-col>
										<v-col cols="4" lg="4" md="12" sm="12" class="s-col-form">
											<s-time
												v-model="activity.RpdEndHour"
												label="Hora Final"
											>
											</s-time>
										</v-col>
										<v-col cols="4" lg="4" md="12" sm="12" class="s-col-form">
											<s-text
												v-model="activity.RpdActivity"
												label="Actividad"
											>
											</s-text>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" lg="12" md="12" sm="12">
											<v-data-table
												:headers="headerActivities"
												:items="activities"
											>

											</v-data-table>
										</v-col>
									</v-row>
								</v-container>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sArea from "../../../services/General/AreaService.js";
	import sSelectWorker from '@/components/Utils/Worker/sSelectWorker';

	export default {
		components: {
			sSelectWorker,
		},

		data() {
			return {
				filter: {},
				config: {
					model: {
						RpwID : "ID"
					},
					service: _sArea,
					headers: [
						{text: "Hora Inicio", value: "RpdBeginHour"},
						{text: "Hora Final", value: "RpdEndHour"},
						{text: "Actividad", value: "RpdActivity"}
					]
				},
				headerActivities: [
					{text: "Hora Inicio", value: "RpdBeginHour"},
					{text: "Hora Final", value: "RpdEndHour"},
					{text: "Actividad", value: "RpdActivity"}
				],
				activities: [],
				activity: {}
			}
		},

		methods: {
			addActivity() {
				if(this.activity.RpdBeginHour == "" || this.activity.RpdBeginHour == undefined){
					this.$fun.alert("Ingrese hora de inicio", "warning");
					return;
				}

				if(this.activity.RpdEndHour == "" || this.activity.RpdEndHour == undefined){
					this.$fun.alert("Ingrese hora final", "warning");
					return;
				}

				if(this.activity.RpdActivity == ""){
					this.$fun.alert("Ingrese actividad", "warning");
					return;
				}

				this.activity.Line = this.activities.length + 1;
				this.activities.push(this.activity);
				this.activity = {}
			}
		},
	}
</script>

<style lang="scss" scoped>

</style>